import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from '../components/article'

import buchetulImage from '../images/buchetul.jpg';
import rochiaImage from '../images/rochia1.jpg';


const IndexPage = () => (
  <Layout>
    <SEO title="Every girl dreams to have her special day, to be bride and shine" />
  
    <div className="drawer">
     
      <Link to="primiipasi" >
        <Article 
          title="Primii pași în organizarea nunții" 
          image="https://images.unsplash.com/photo-1548765015-1e047ff5c9e9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=200"
          meta="Ai spus DA! Ce urmeaza acum?" 
        />
      </Link>
      <Link to="tematica" >
        <Article 
          title="Nuntă cu tematică" 
          image="https://images.unsplash.com/photo-1507915977619-6ccfe8003ae6?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="Sunt micile detalii care fac diferenta" 
        />
      </Link>
      <Link to="florile" >
        <Article 
          title="Cum alegem florile?" 
          image="https://images.unsplash.com/photo-1487530811176-3780de880c2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
          meta="💐 🌹 🌷 🌸" 
        />
      </Link>
      <Link to="muzica" >
        <Article 
          title="Muzica la nuntă" 
          image="https://images.unsplash.com/photo-1482575832494-771f74bf6857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
          meta="Formație sau DJ?" 
        />
      </Link>
      <Link to="invitatiile" >
        <Article 
          title="Cum alegem invitațiile de nuntă?" 
          image="https://images.unsplash.com/photo-1494319827402-c4b839aed26b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
          meta="Câteva idei de invitații" 
        />
      </Link>
      <Link to="cununiacivila" >
        <Article 
          title="Cununia Civilă" 
          image="https://images.unsplash.com/photo-1511285560929-80b456fea0bc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
          meta="La ce trebuie să ne gândim pentru organizare cununiei civile?" 
        />
      </Link>
      <Link to="buchetul" >
        <Article 
          title="Buchetul de mireasă" 
          image={buchetulImage}
          meta="Bujori, lalele, liliac, hortensie?" 
        />
      </Link>
      <Link to="pastrareaBuchetului" >
        <Article 
          title="Cum păstrăm buchetul de mireasă?" 
          image="https://images.unsplash.com/photo-1527037138969-12b5ffa36ebb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
          meta="Cateva metode sa pastrezi amintirea zilei" 
        />
      </Link>
      <Link to="rochia" >
        <Article 
          title="Rochia de mireasă" 
          image={rochiaImage}
          meta="Cum alegi totuși rochia potrivită?" 
        />
      </Link>
      <Link to="cununiaReligioasa" >
        <Article 
          title="Cununia religioasa" 
          image="https://images.unsplash.com/photo-1522143296900-b2c450f80fa7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="Ce trebuie să știi înainte de cununia religioasă" 
        />
      </Link>
      <Link to="bauturaCocktail" >
        <Article 
          title="Băutura la nuntă și Cocktail bar" 
          image="https://images.unsplash.com/photo-1560963689-7f69d8ca0518?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="Avantajele unui cocktail bar" 
        />
      </Link>
      <Link to="candyBar" >
        <Article 
          title="Candy Bar" 
          image="https://images.unsplash.com/photo-1580338265284-4826d25b3478?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80"
          meta="🧁" 
        />
      </Link>

      <Link to="marturiile" >
        <Article 
          title="Mărturiile" 
          image="https://images.unsplash.com/photo-1520095729726-7fc38987e4a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80"
          meta="Infățișarea amintirilor de la nunta " 
        />
      </Link>
      <Link to="tortul" >
        <Article 
          title="Cum alegem tortul?" 
          image="https://images.unsplash.com/photo-1596567503289-6c12aed24902?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=200&q=80"
          meta="🎂" 
        />
      </Link>
      <Link to="mancarea" >
        <Article 
          title="Mâncarea de la nuntă" 
          image="https://images.unsplash.com/photo-1580682197386-8746a263c370?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="Meniul clasic sau bufet suedez?" 
        />
      </Link>
      <Link to="guestBook" >
        <Article 
          title="Guest Book/ Cartea de oaspeți" 
          image="https://images.unsplash.com/photo-1527983159646-0e98887ad0cf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="Afla cum s-au simțit invitații în seara evenimentului" 
        />
      </Link>
      <Link to="honeyMoon" >
        <Article 
          title="Honey Moon" 
          image="https://images.unsplash.com/photo-1496602910407-bacda74a0fe4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
          meta="O vacanță în care doar voi doi să vă bucurați de timp împreună" 
        />
      </Link>
      
    
    </div>
  </Layout>
)

export default IndexPage
