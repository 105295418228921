import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 512px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  margin: 24px auto;
  background: #fff;


  h5, p {
    margin: 0;
  }

  .imageContainer {
    position: relative;
    width: 96px;
    height: 72px;
    margin-right: 24px;
    
    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${props => props.img});
    }
  }

  .description {
     .title {
      display: block;
      font-weight: 500;
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      color: #181818;
      margin-top: 12px;
     }

     @media(max-width:480px) {
       .title {
         margin-top: 0;
       }
     }

     span {
      font-weight: 400;
      line-height: 20px;
      max-width: 100%;
      padding-top: 4px;
      color: #555555;
      font-size: 12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
     }
  }
`;

class Article extends React.Component {
  render() {
    return (
      <Container img={this.props.image}>
        <div className="imageContainer">
          <div className="image"></div>
        </div>
        <div className="description">
          <p className="title">{this.props.title}</p>
          <span>{this.props.meta}</span>
        </div>
      </Container>
    );
  }
} 

export default Article;

